<template>
  <header v-if="isContact" class="pb-0 mb-0">
    <div class="container">
      <div class="logo"></div>
      <div class="headernav">
        <!-- <router-link to="/">Home</router-link> -->
        <div>Ingelogd als {{ username }} - <a href="#" class="text-dark" @click.prevent="logout">Afmelden</a></div>
        <template v-if="isContact && !hasOneDossier">
          <router-link class="text-green text-end w-100 d-block" to="/me/dossiers">Mijn dossiers</router-link>
        </template>
      </div>
    </div>
  </header>

</template>
<script>
  import store from '@/store/user'
  import contactStore from '@/store/contact'
  import loginservice from '@/services/contact/LoginService'

  export default {
    name: 'navHeader',
    computed: {
      isContact: function () {
        return contactStore.getters.isContact
      },
      hasOneDossier: function () {
        return contactStore.getters.hasOneDossier
      },
      isAdmin: function () {
        return store.getters.isAdmin
      },
      username: function () {
        return contactStore.getters.getName
      }
    },
    methods: {
      logout() {
        loginservice.logout().then(response => {
          contactStore.dispatch('userLogout')
          // this.$toast.success('Logout success')
          if (this.isAdmin) {
            this.$router.push({
              name: "adminHome"
            })
          } else {
            this.$router.push({
              name: "contactAccount",
              params: {
                type: 'login'
              }
            })
          }
        }).catch(error => {
          this.$toast.error('Logout failed')
        })
      }
    }
  }
</script>