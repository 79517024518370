import http from '@/http-common-contact'

const URL = '/api/contact'

class LoginService {
  login(data) {
    return http.post(`${URL}/login`, data)
  }
  logout() {
    return http.post(`${URL}/logout`)
  }
  forgot(data) {
    return http.post(`${URL}/password/forgot`, data)
  }
  reset(data) {
    return http.post(`${URL}/password/reset`, data)
  }
}

export default new LoginService()