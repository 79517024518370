<template>
   <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 vh-100" v-if="isAdmin">
      <div class="position-fixed d-flex flex-column align-items-center align-items-sm-start ps-3 pt-4 bg-white min-vh-100" id="nav">
         <router-link to="/"
            class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
            <span class="fs-5 d-none d-sm-inline">Apple Tree Portaal</span>
         </router-link>
         <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
            <li class="nav-item">
               <router-link to="/" class="nav-link align-middle px-0">
                  <i class="fa-light fa-house-blank"></i> Home
               </router-link>
            </li>
            <!-- <li class="w-100">
               <router-link to="/contacts" class="nav-link px-0"><i class="fa-light fa-file-contract" />
                  Contacten
               </router-link>
            </li> -->
            <li class="w-100">
               <router-link to="/dossiers" class="nav-link px-0">
                  <i class="fa-light fa-folder-open" /> Dossiers
               </router-link>
            </li>
            <li class="w-100">
               <router-link to="/pension-companies" class="nav-link px-0">
                  <i class="fa-light fa-industry"></i> Maatschappijen
               </router-link>
            </li>
            <li class="w-100">
               <router-link to="/sources" class="nav-link px-0">
                  <i class="fa-light fa-globe"></i> Bronwebsites
               </router-link>
            </li>
            <li class="w-100">
               <router-link to="/invoices" class="nav-link px-0">
                  <i class="fa-light fa-file"></i> Factuurexport
               </router-link>
            </li>
            <li>
               <router-link to="/users" class="nav-link px-0 align-middle">
                  <i class="fa-light fa-user"></i> Gebruikers
               </router-link>
            </li>
            <li>
               <router-link to="/logs" class="nav-link px-0 align-middle">
                  <i class="fa-light fa-line-columns"></i> Logs
               </router-link>
            </li>
         </ul>
         <hr>
         <div class="dropdown pb-4">
            <a href="#" class="d-flex align-items-center text-decoration-none dropdown-toggle"
               id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
               <!-- <img src="https://avatars.githubusercontent.com/u/7062570?s=96&v=4" alt="hugenerd" width="30" height="30" class="rounded-circle"> -->
               <span class="d-none d-sm-inline mx-1">{{loggedUserName}}</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
               <!--    <li>
                  <router-link class="dropdown-item" to="/account/login">Contact login</router-link>
               </li>
               <li><a class="dropdown-item" href="#">Settings</a></li>
                     <li><a class="dropdown-item" href="#">Profile</a></li>
                     <li>
                       <hr class="dropdown-divider">
                     </li> -->
               <li><a class="nav-link dropdown-item" @click="logout">Log uit</a></li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
   import store from '@/store/user'
   import loginservice from '@/services/admin/LoginService'

   export default {
      name: 'adminNavHeader',
      computed: {
         isAdmin: function () {
            return store.getters.isAdmin
         },
         loggedUserName: function () {
            return store.getters.getName
         }
      },
      methods: {
         logout() {
            loginservice.logout().then(response => {
               store.dispatch('userLogout')
               this.$router.push('/')
            }).catch(error => {
               this.$toast.error('Failed to log out')
            })
         }
      }
   }
</script>

<style scoped>
   .fa-light { width: 1.5rem }
   #nav {
      width: 16vw;
      min-width: 200px
   }
</style>