import { createStore } from 'vuex'
import http from '@/http-common'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: null,
    token_expiration: null,
    user: {
      first_name: null,
      last_name: null,
      id: null,
      email: null,
      role: null
    }
  },
  plugins: [createPersistedState({
    "key": "user"
  })],
  mutations: {
    LOGIN_SUCCESS(state, userData) {
        state.token = userData.token
        state.token_expiration = userData.token_expiration
        state.user.id = userData.id
        state.user.first_name = userData.first_name
        state.user.last_name = userData.last_name
        state.user.email = userData.email
        state.user.role = userData.role
      },
    LOGOUT_SUCCESS(state) {
        state.token = null
        state.token_expiration = null
        state.user = {
          id: null,
          first_name: null,
          last_name: null,
          email: null,
          role: null
        }
    }
  },
  actions: {
    userLogin({getters, commit}, response) {
      commit('LOGIN_SUCCESS', response)
      http.defaults['headers']['Authorization'] = getters.getAuthHeader
    },
    userLogout({commit}, response) {
      commit('LOGOUT_SUCCESS', response)
      sessionStorage.clear();
      http.defaults['headers']['Authorization'] = null
    }
  },
  modules: {
  },
  getters: {
    getAuthHeader: state => {
      if (state.token != null) {
        return 'Bearer ' + state.token
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      if (state.user.email != null) {
        return true
      } else {
        return false
      }
    },
    isAdmin: state => {
      if (state.user.role === 'admin') {
        return true
      } else {
        return false
      }
    },
    getName: state => {
      if (state.user.last_name) {
        return state.user.first_name + ' ' + state.user.last_name
      }
      
      return state.user.first_name
    },
    isContact: state => {
      if (state.user.role === 'contact') {
        return true
      } else {
        return false
      }
    },
    userId: state => {
        return state.user.id
    },
  }
})
