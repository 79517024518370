import http from '@/http-common'

const URL = '/api/admin/user'

class LoginService {
  login(data) {
    return http.post(`${URL}/login`, data)
  }
  contactLogin(contactId) {
    return http.post(`${URL}/contact/${contactId}/login`)
  }
  setContactPassword(data, contactId) {
    return http.post(`${URL}/contact/${contactId}/set-password`, data)
  }
  logout() {
    return http.post(`${URL}/logout`)
  }
  forgot(data) {
    return http.post(`${URL}/password/forgot`, data)
  }
  reset(data) {
    return http.post(`${URL}/password/reset`, data)
  }
}

export default new LoginService()