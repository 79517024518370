import { createStore } from 'vuex'
import http from '@/http-common-contact'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: null,
    token_expiration: null,
    user: {
      name: null,
      id: null,
      email: null,
      full_name: null,
      gender: null,
      role: null,
      dossierId: null
    }
  },
  plugins: [createPersistedState({
    "key": "contact"
  })],
  mutations: {
    LOGIN_SUCCESS(state, userData) {
        state.token = userData.token
        state.token_expiration = userData.token_expiration
        state.user.id = userData.id
        state.user.name = userData.name
        state.user.full_name = userData.full_name
        state.user.gender = userData.gender
        state.user.email = userData.email
        state.user.role = userData.role
        state.user.dossierId = userData.dossierId
      },
    LOGOUT_SUCCESS(state) {
        state.token = null
        state.token_expiration = null
        state.user = {
          id: null,
          name: null,
          full_name: null,
          email: null,
          gender: null,
          role: null,
          dossierId: null
        }
    }
  },
  actions: {
    userLogin({getters, commit}, response) {
      commit('LOGIN_SUCCESS', response)
      http.defaults['headers']['Authorization'] = getters.getAuthHeader
    },
    userLogout({commit}, response) {
      commit('LOGOUT_SUCCESS', response)
      sessionStorage.clear();
      http.defaults['headers']['Authorization'] = null
    }
  },
  modules: {
  },
  getters: {
    getAuthHeader: state => {
      if (state.token != null) {
        return 'Bearer ' + state.token
      } else {
        return ''
      }
    },
    isLoggedIn: state => {
      if (state.user.email != null) {
        return true
      } else {
        return false
      }
    },
    hasOneDossier: state => {
      if (state.user.dossierId == false || state.user.dossierId == null) {
        return false
      } else {
        return true
      }
    },
    isAdmin: state => {
      if (state.user.role === 'admin') {
        return true
      } else {
        return false
      }
    },
    getName: state => {
        return  state.user.full_name
    },
    isContact: state => {
      if (state.user.role === 'contact') {
        return true
      } else {
        return false
      }
    },
    userId: state => {
        return state.user.id
    },
  }
})
