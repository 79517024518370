import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-toast-notification/dist/theme-default.css';
import vueToast from 'vue-toast-notification';
import PrimeVue from 'primevue/config';
import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import moment from 'moment'
const app = createApp(App);
// Sentry.init({
//     app,
//     dsn: "https://b055e0098bd64a828e97891b8cab741a@o1072871.ingest.sentry.io/6072461",
//     integrations: [
//       new Integrations.BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//       }),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });


app.use(store).use(router).use(vueToast).use(PrimeVue).mixin({
    methods: {
        formatDate (value: string, type: string) {
            if(value === null){
                return '-'
            }
            const definedMoment = moment(value).locale('nl')
            if (type == 'dateTime') {
                return definedMoment.format('DD-MM-YYYY HH:mm')
            } else if (type == 'fromNow') {
                return definedMoment.fromNow()
            } else if (type == 'isoDate') {
                return definedMoment.format('YYYY-MM-DD')
            } else if (type == 'shortDate') {
                return definedMoment.format('DD-MM-YYYY')
            } else if (type == 'longDate') {
                return definedMoment.format('D MMMM YYYY')
            }

            return definedMoment.format('YYYY-MM-DD')
        },
        formatCurrency (value: number, symbol = '€', fractionDigits = 2) {
            const nValue = Number(value.toString().replaceAll(',', '.'));
            const prefix = symbol ? (symbol + ' ') : '';
            return prefix + new Intl.NumberFormat('en-NL', {minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits}).format(nValue)
        },
        getName(item : any){
            const fname = item.first_name != null ? item.first_name + ' ' : '';
            const lname = item.last_name != null ? item.last_name : '';
            return fname + lname;
        },
        setDownloadFileName(number: any, name: any, type: string, extension = 'pdf'){
            let fullType = type;
            if(type === 'factuur' || type === 'offerte' || type == 'aanvraag_formulier'){
                fullType = type + ' van Apple Tree';
            }
            return `${number} - ${name} - ${fullType.replaceAll('_', ' ')}.${extension}`
        },
        showValidity(field: any, isValid: boolean){
            if(isValid){
              field.classList.add('is-valid');
              field.classList.remove('is-invalid');
            }
            else{
              field.classList.add('is-invalid');
              field.classList.remove('is-valid');
            }
        },
        formatMonthPeriod (value: number, includePer = true) {
            let returnText = includePer ? 'per ' : '';
            if (value == 0) {
                returnText = ''
            } else if (value == 1) {
                returnText += 'Maand'
            } else if (value == 3) {
                returnText += 'Kwartaal'
            } else if (value == 6) {
                returnText += 'Half jaar'
            } else if (value == 12) {
                returnText += 'Jaar'
            } else {
                returnText += (value + ' maanden')
            }
            return (includePer ? returnText.toLowerCase() : returnText);
        },
        setButtonLoadingIcon(el: HTMLElement, active: boolean){
            const spinner = el.firstElementChild;
            if(!spinner) return;
            if(active){
                let originalClass = '';
                spinner.classList.forEach(someClass => {
                    if(someClass.startsWith('fa-')){
                        originalClass = someClass;
                    }
                });
                spinner.classList.replace(originalClass, 'inactive-' + originalClass);
                spinner.classList.add('fa-spin', 'fa-spinner');
                el.setAttribute('disabled', "");
            }
            
            else{
                let alteredClass = '';
                spinner.classList.forEach(someClass => {
                    if(someClass.startsWith('inactive-fa-')){
                        alteredClass = someClass;
                    }
                });
                spinner.classList.replace(alteredClass, alteredClass.substring(9));
                spinner.classList.remove('fa-spin', 'fa-spinner');
                el.removeAttribute('disabled');
            }
        }
    }
})

Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost:8080", "https://portal.apple-tree.nl/", "https://portaal.apple-tree.nl/", "https://mijn.apple-tree.nl/", /^\//],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    trackComponents: true,
    logErrors: true
});

app.mount('#app');