<template>
  <div class="container-fluid">
    <div v-if="!isProduction" id="testingalert" class="alert alert-warning" role="alert">
      <i class="fa-light fa-triangle-exclamation"></i>
      Dit is een testserver
      <i class="fa-light fa-triangle-exclamation"></i>
    </div>
    <div class="row flex-nowrap">
      <NavHeader v-if="!isAdmin" />
    </div>
    <div class="row flex-nowrap">
      <AdminNavHeader v-if="isAdmin && isLoggedIn"/>
      <div :class="{'col big-margins' : isLoggedIn}">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
  import NavHeader from '@/components/NavHeader.vue'
  import AdminNavHeader from '@/components/admin/AdminNavHeader.vue'
  import store from '@/store/user'
  import contactStore from '@/store/contact'
  import http from '@/http-common'
  import httpContact from '@/http-common-contact'
  import router from '@/router'

  export default {
    components: {
      NavHeader,
      AdminNavHeader
    },
    computed: {
      isAdmin() {
        return window.location.host.split('.')[0] == 'portal' || window.location.host.split('.')[0] == 'portaal' || window.location.host.split('.')[0] == 'mariadb-portaal'
      },
      isLoggedIn() {
        return contactStore.getters.isContact || store.getters.isAdmin
      },
      isProduction() {
        // console.log('false', process.env.VUE_APP_IS_PRODUCTION);
        return process.env.VUE_APP_IS_PRODUCTION == "true";
      }
    },
    mounted() {
      http.defaults['headers']['Authorization'] = store.getters.getAuthHeader
      httpContact.defaults['headers']['Authorization'] = contactStore.getters.getAuthHeader
      document.title = window.location.href.includes('portaal.apple') || window.location.href.includes('portal.apple') ? 'Apple Tree Portaal' : 'Mijn Apple Tree';
    }
  }

  // Catch unauthenticated api calls and redirects to either /admin or /
  http.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
      store.dispatch('userLogout')
      router.push('/')
    }
    return Promise.reject(error)
  })
  httpContact.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response.status === 401) {
      contactStore.dispatch('userLogout')
      router.push('/account/login')
    }
    return Promise.reject(error)
  })
</script>

<style>
  #nav {
    padding: 30px;
  }

  #nav li {
    width: 100%;
  }

  #nav .nav-link{
    position: relative;
    left: 0px;
    transition-property: left, color;
    transition-duration: 0.25s;
  }

  #nav a:hover,
  #nav a.router-link-exact-active:hover {
    color: #0a39f6;
    left: .5rem;
  }

  #nav a.router-link-exact-active {
    color: #4b4764;
  }

  .info-icon {
     color: orange;
  }

  .info-icon + span {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: .5rem;
    padding: .5rem;
    margin-left: 1rem;
    margin-top: -.5rem;
    z-index: 10;
  }
  .info-icon:hover + span {
    display: inline;
  }
  .info-icon:hover + span::before {
    content: "\25C0";
    position: absolute;
    margin-left: -21px;
  }

  
#testingalert {
  width: auto;
  z-index: 99999;
  position: absolute;
  left: calc(130px);
  top: 10px;
  transform: translateX(-50%);
}

</style>